import React, {useEffect, useState} from 'react';
import Navbar from './shared/Nav/Nav';
import Home from './home/Home/Home';
import Footer from './shared/Footer/Footer';
import useRSSData from '../hooks/PodcastRSSHook';
import { BrowserRouter, Route } from 'react-router-dom';
import Episode from './episodes/Episode';
import {getItemFromLocalStorage} from "../localStorage";
import Cookies from "./common/Cookies/Cookies";

function App() {
  const podcastEpisodes = useRSSData();
  const [filter, setFilter] = useState("");
  const [episodes, setEpisodes] = useState([]);
  
  useEffect(()=> {
     const filteredList = podcastEpisodes && podcastEpisodes.filter((episode) => episode.title.toLowerCase().includes(filter))
      setEpisodes(filteredList);
  }, [filter, podcastEpisodes]);

  return (
    <BrowserRouter>
      {getItemFromLocalStorage("cookie") ? "" : <Cookies/>}
      <Navbar search={setFilter} />
      <Route path="/" exact={true} render={(props) => <Home {...props} podcastEpisodes={episodes} />} />
      <Route path="/episodios/:id" exact={true} render={(props) => <Episode {...props} podcastEpisodes={podcastEpisodes} />} />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
