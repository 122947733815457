import { useState, useEffect } from 'react';
import { xml2js } from 'xml-js';
import {
  extractSynopsis,
  extractTags,
  extractReferences,
  extractSpotifyPlaylist
} from '../helpers/dataExtractionHelpers';

const createEpisodeData = rssItem => {
  return {
    title: rssItem.title._cdata,
    image: rssItem['itunes:image']._attributes.href,
    link: rssItem.link._text,
    synopsis: extractSynopsis(rssItem.description._cdata),
    tags: extractTags(rssItem.description._cdata),
    references: extractReferences(rssItem.description._cdata),
    playlist: extractSpotifyPlaylist(rssItem.description._cdata)
  }
};

const formatRSSData = data => data.rss.channel.item.map(createEpisodeData);

const useRSSData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://anchor.fm/s/dcfdbb0/podcast/rss")
      .then(response => response.text())
      .then(xmlText => {
       const jsonData = xml2js(xmlText, { compact: true });
       setData(formatRSSData(jsonData));
      });
  }, []);

  return data;
}

export default useRSSData;
