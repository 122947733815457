/**
 * This regex will match any text between two HTML tag parts. Parts are meant as
 * any beginning or end of a tag, or even a standalone tag. Ex: <p>, <br>, </i>, etc.
 */
const synopsisRegex = />([^<]*)/mi;
/**
 * This regex will match the full text starting from the word "tags" until the end of
 * the line
 */
const tagsTextRegex = /tags([\s\S]*)/gmi;
/**
 * This regex will find words starting with the "#" character
 */
const tagRegex = /(\#[a-zA-Z\u00C0-\u00FF_-]*)/gmi;
/**
 * regex to extract references as li tags
 */
const referenceItemsRegex = /<\s*li[^>]*>(.*?)\s*<\s*\/\s*li>/gis;
/**
 * regex to extract a name from an reference
 */
const referenceNameRegex = />([^<]+)/gmi;
/**
 * regex to get a link from an href attribute
 */
const referenceLinkRegex = /href=\"([^"]*)"/mi;
/**
 * regex to remove links without an 'a' tag
 */
const referenceAnchorRegex = /(?:https?|ftp):\/\/[\n\S]+/g
/**
 * regex to remove html
 */
const referenceHtmlRegex = /[\<|\>]/g;
/**
 * regex to ensures that the selected text is not a symbol or a non alphanumeric text
 */
const referenceSymbolRegex = /[^a-zA-Z\u00C0-\u00FF]/g;
/**
 * regex to get the playlist spotify link
 */
const playlistRegex = /href="(https:\/\/open\.spotify\.com\/[^"]*)/mis;

export const extractSynopsis = description => {
  const matches = description.match(synopsisRegex);
  return matches ? matches[1] : "";//the first element is a full match and should be skipped
}

const extractTagsText = description => {
  const matches = description.match(tagsTextRegex);
  return matches ? matches[0] : "";
}

export const extractTags = description => {
  const tagsText = extractTagsText(description);
  const matches = tagsText.match(tagRegex);

  return matches ? matches : [];
}

export const extractReferences = description => {
  let references = []
  // match returns null instead of an empty array then there is no results, so, this is to prevent runtime errors 
  if (!description.match(referenceItemsRegex)) {
    return references;
  }

  description.match(referenceItemsRegex).forEach((referenceItem, index) => {
      const link = referenceLinkRegex.exec(referenceItem)[1]
      let name = link
      if (referenceItem.match(referenceNameRegex)) {
        referenceItem.match(referenceNameRegex).forEach((rawReferenceName, index) => {
          let referenceName = rawReferenceName.replace(referenceAnchorRegex, '');
          referenceName = referenceName.replace(referenceHtmlRegex, '');
          if (referenceName.replace(referenceSymbolRegex, '').length > 0) {
            name = referenceName
          }
        })
      }
      references.push({name, link})
  })
  return references;
}

export const extractSpotifyPlaylist = description => {
  let playlist = "";

  // match returns null instead of an empty array then there is no results, so, this is to prevent runtime errors
  if (!description.match(playlistRegex)) {
    return playlist;
  }

  description.match(playlistRegex).forEach((match, index) => {
    if (index > 0) { // ignores full match
      playlist = match;
    }
  })
  playlist = playlist.replace(/https:\/\/open\.spotify\.com\//is, "https://open.spotify.com/embed/")
  return playlist;
}
