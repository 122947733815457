import React, { useState } from 'react';
import './SearchBar.scss';
import { ReactComponent as AddIcon } from "../../../images/add.svg";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";

const getUniqueTags = podcastEpisodes => {
  const tags = [];

  podcastEpisodes && podcastEpisodes.forEach(episode => {
    episode.tags.forEach(tag => {
      if (tags.indexOf(tag) === -1) {
        tags.push(tag);
      }
    })
  });
  return tags;
}

const SearchBar = ({ podcastEpisodes, selectedTags, setSelectedTags }) => {
  const tags = getUniqueTags(podcastEpisodes);
  const [showTags, setShowTags] = useState(false);
  const toggleTagVisibility = () => setShowTags(!showTags);

  const handleChange = tag => {
    const indexTag = selectedTags.indexOf(tag);
    const newStateTags = [...selectedTags];
    if (indexTag !== -1) {
      newStateTags.splice(indexTag, 1);
    } else {
      newStateTags.push(tag);
    }
    setSelectedTags(newStateTags);
  }

  return (
    <section className="SearchBar">
      <div className="search-field">
        <div className="search-field-title">
          <h2>Jukebox, o podcast da Mastertech.</h2>
          <span className="tagline">Aprendizado com música e negócios além da sala de aula.</span>
        </div>
        <aside>
          <button onClick={toggleTagVisibility}>
            {showTags ?
              <CloseIcon /> : <AddIcon />
            }
            {showTags ?
              <span class="desktop-button">ver menos</span> : <span class="desktop-button">filtre por tópicos +</span>
            }
          </button>
        </aside>
      </div>
      <div>
        {selectedTags && selectedTags.map((item, index) => (
          <button key={index} className="selected-tags">{item}</button>
        ))}
      </div>
      {showTags && (
        <div className="tag-field" >
          {tags.map((tag, index) => {
            let tagClassname = "tag";
            if (selectedTags.indexOf(tag) !== -1) {
              tagClassname = "tag active";
            }
            return <button key={index} className={tagClassname}
              onClick={() => handleChange(tag)}>{tag}</button>;
          })}
        </div>
      )}
    </section>
  );
}

export default SearchBar;
