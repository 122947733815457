import React from 'react';
import './Main.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as Plus } from "../../../images/plus.svg";

const Main = ({ podcastEpisodes, selectedTags }) => {
  const episodesQuantity = podcastEpisodes && podcastEpisodes.length;

  return (
    <section className="Main">
      <article>
        {podcastEpisodes && podcastEpisodes.map((item, index) => {
          let shouldAppearTag = true;

          if (selectedTags.length > 0) {
            shouldAppearTag = false;
            for (const namedTag of item.tags) {
              if (selectedTags.indexOf(namedTag) !== -1) {
                shouldAppearTag = true;
              }
            }
          }

          return (<div className={shouldAppearTag ? "content" : "content inactive"}>
            <Link to={`/episodios/${episodesQuantity - index}`}>
              <img key={index} src={item.image} alt={item.title} />
            </Link>
            <div className="overlay">
              <div className="text">
                <Link to={`/episodios/${episodesQuantity - index}`}>
                  <p>{item.title}</p>
                  <button key={episodesQuantity - index}><Plus /></button>
                </Link>
              </div>
            </div>
          </div>
          )
        })}
      </article>
    </section>
  );
}

export default Main;
