import React from 'react';
import './Nav.scss';
import { useState } from 'react';

const Navbar = ({ search }) => {
  const [menuActive, setMenuActive] = useState(false);

  const handleMenuClick = () => setMenuActive(!menuActive);

  return (
    <nav className={`Navbar${menuActive ? ' active' : ''}`}>
      <a href="/"><img src="https://jukebox-files.s3-sa-east-1.amazonaws.com/logo_jukebox.png" alt=""/></a>
      <div className="hamburguer-icon" onClick={handleMenuClick}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="menu-items">
        <a href="/">LISTA DE EPISÓDIOS</a>
        <a href="https://mastertech.com.br/" target="_blank" rel="noopener noreferrer">A ESCOLA</a>
        <input type="text" placeholder="Buscar por artista" onChange={ e => search(e.target.value.toLowerCase())}/>
      </div>
    </nav>
    );
}

export default Navbar;
