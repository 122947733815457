import React, { useEffect } from 'react';
import './Episode.scss';
import { useParams, Link } from 'react-router-dom';
import play from '../../images/play1.png';

const Episode = ({ podcastEpisodes }) => {
  const params = useParams();
  const episodesQuantity = podcastEpisodes && podcastEpisodes.length;
  const episodeId = params.id;
  const position = episodesQuantity - episodeId;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return episodesQuantity !== 0 && (
    <section className="Episode">
      <div>
        <img src={podcastEpisodes && podcastEpisodes[position].image} alt="" />
        <div className="title">
          <a rel="noopener noreferrer" href={podcastEpisodes && podcastEpisodes[position].link}><h1>{podcastEpisodes && podcastEpisodes[position].title}</h1></a>
          <a rel="noopener noreferrer" href={podcastEpisodes && podcastEpisodes[position].link}><img src={play} className="play-icon" alt="play para o podcast"></img></a>
        </div>
        <h3>Sinopse</h3>
        <p>{podcastEpisodes && podcastEpisodes[position].synopsis}</p>
      </div>
      <div>
        <h3>Playlist</h3>
        {podcastEpisodes && podcastEpisodes[position].playlist && (
          <iframe title="playlist" src={podcastEpisodes && podcastEpisodes[position].playlist} frameBorder="0" allowTransparency="true"
            allow="encrypted-media"></iframe>
        )}
      </div>
      <div className="tags-links">
        <h3>Tags</h3>
        <div className="EpisodeTags">
          {podcastEpisodes && podcastEpisodes[position].tags.map((item, index) => (
            <Link to={{pathname: "/", state: { tagName: item }}}>
              <button key={index}>{item}</button>
            </Link>
          ))}
        </div>
        <h3 className="related-link-title">Links relacionados</h3>
        {podcastEpisodes && podcastEpisodes[position].references.map((item, index) => (
          <a href={item.link}><p className="related-links" key={index}> {item.name}</p></a>
        ))}
      </div>
    </section>
  );
}

export default Episode;
