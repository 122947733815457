import React, { useState, useEffect } from 'react';
import SearchBar from '../SearchBar/SearchBar';
import Main from '../Main/Main';
import { useLocation } from 'react-router-dom';

const Home = ({ podcastEpisodes }) => {
  const location = useLocation();
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    if (location.state && location.state.tagName) {
      setSelectedTags([location.state.tagName]);
    }
  }, [location]);

  return (
    <>
      <SearchBar podcastEpisodes={podcastEpisodes} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
      <Main podcastEpisodes={podcastEpisodes} selectedTags={selectedTags} />
    </>
  );
}

export default Home;
